import React, { useCallback } from 'react';
import { Map } from 'react-leaflet';
import { Map as LeafletMap } from 'leaflet';
import { GestureHandling } from "leaflet-gesture-handling";
import { TileLayer } from '@entegral/helpers.leaflet/components';

import { useZoom } from '@entegral/helpers.leaflet/hooks';
import { usePinPointsBoundsLeaflet as useBounds } from '@entegral/domains.pin-point/hooks';

import { PinPointGroupMarkerLeaflet } from '@entegral/domains.pin-point/components';

LeafletMap.addInitHook('addHandler', 'gestureHandling', GestureHandling);

export function ReviewMap({
  pinPoints,
  pinPointsGroupsHook,
  tilesId,
  accessToken,
  baseClassName,
  context,
  useCtrlScrollZoom,
  defaultViewCoords,
  mapAlias,
}) {
  let [zoom, setZoom] = useZoom();
  let [bounds, fitBounds] = useBounds(pinPoints, defaultViewCoords);
  let groups = pinPointsGroupsHook(pinPoints, zoom, context);

  let markers = groups && groups.map(x => (
    <PinPointGroupMarkerLeaflet
      key={`${x.coords[0]}/${x.coords[1]}/${zoom}`}
      group={x}
      baseClassName={baseClassName}
      fitBounds={fitBounds}
    />
  ));

  let getMapInstance = useCallback(node => {
    if (!mapAlias) return;

    window[mapAlias] = node.leafletElement;
  }, [mapAlias]);

  return (
    <Map
      className={`${baseClassName}__container`}
      bounds={bounds}
      onViewportChanged={setZoom}
      onZoomend={setZoom}
      maxZoom={19}
      gestureHandling={useCtrlScrollZoom}
      ref={getMapInstance}
      tap={false}
    >
      <TileLayer accessToken={accessToken} tilesId={tilesId} />
      {markers}
    </Map>
  );
}
