module.exports = function thousandSeparator(value, delimiter=',') {
  if (!value) return 0;

  value = value.toString();

  let integerPart = parseInt(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
  let decimalPart = value.includes('.') ? `.${value.split('.')[1]}` : '';

  if (decimalPart.length < 2) {
    decimalPart = '';
  }

  if (decimalPart) {
    decimalPart = decimalPart.substring(0, 3);
  }

  return `${integerPart}${decimalPart}`;
};
